export const RouteFilterPaginationMixin = {
    methods: {
        filterWatcher (val) {
            for (const key in val) {
                if (!val[key]) {
                    this.queryDelete(val[key]);
                }
            }
        },
        queryDelete(key) {
            const query = {...this.$route.query};

            delete query[key];

            this.$router.replace({
                query: query
            }).catch(() => {
            });
        },
        routeFiltersInit(filters) {
            const query = this.$route.query;
            for (const key in query) {
                if (query[key] && Object.prototype.hasOwnProperty.call(filters, key)) {
                    filters[key] = key;
                    if (!isNaN(+query[key])) {
                        filters[key] = +query[key];
                    } else if (Array.isArray(query[key])) {
                        const array = [...query[key]].map((i) => {
                            if (!isNaN(+i)) {
                                return +i;
                            }
                        })
                        filters[key] = [...array];
                    } else if (query[key] === 'true' || query[key] === 'false') {
                        filters[key] = query[key] === 'true';
                    } else {
                        filters[key] = query[key];
                    }
                }
            }
        },
    },
}
