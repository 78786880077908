const moment = require('moment');

export const eventText = (event) => {
    switch (event) {
        //задачи
        case 'user.task.create':
            return 'создал(а) задачу';
        case 'user.task.update':
            return 'обновил(а) задачу';
        case 'user.task.delete':
            return 'удалил(а) задачу';
        case 'user.task.done':
            return 'завершил(а) задачу';
        case 'user.task.update.executor':
            return 'обновил(а) задачу';
        case 'user.task.update.curator':
            return 'обновил(а) задачу';
        case 'user.task.comment.create':
            return 'обновил(а) задачу';

        //клиенты
        case 'user.client.create':
            return 'добавил(а) клиента';
        case 'user.client.update':
            return 'обновил(а) карточку клиента';
        case 'user.client.update.responsible_user':
            return 'обновил(а) карточку клиента';
        case 'user.client.update.status':
            return 'обновил(а) карточку клиента';
        case 'user.client.comment.create':
            return 'обновил(а) карточку клиента';
        case 'user.client.delete':
            return 'удалил(а) карточку клиента';

        //продукты
        case 'user.product.create':
            return 'добавил(а) продукт';
        case 'user.product.update':
            return 'обновил(а) продукт';
        case 'user.product.update.price':
            return 'обновил(а) продукт';
        case 'user.product.size.create':
            return 'обновил(а) продукт';
        case 'user.product.size.delete':
            return 'обновил(а) продукт';
        case 'user.product.size.update.value':
            return 'обновил(а) продукт';
        case 'user.product.size.update.price':
            return 'обновил(а) продукт';
        case 'user.product.delete':
            return 'удалил(а) продукт';

        //сделки
        case 'user.deal.create':
            return 'создал(а) сделку';
        case 'user.deal.update':
            return 'обновил(а) сделку';
        case 'user.deal.update.status':
            return 'обновил(а) сделку';
        case 'user.deal.comment.create':
            return 'обновил(а) сделку';
        case 'user.deal.update.executor':
            return 'обновил(а) сделку';
        case 'user.deal.product.add':
            return 'обновил(а) сделку';
        case 'user.deal.product.delete':
            return 'обновил(а) сделку';
        case 'user.deal.delete':
            return 'удалил(а) сделку';

        //счета
        case 'user.bill.create':
            return 'выставил(а) счет';
        case 'user.bill.update':
            return 'обновил(а) счет';
        case 'user.bill.update.client':
            return 'обновил(а) счет';
        case 'user.bill.update.deal':
            return 'обновил(а) счет';
        case 'user.bill.delete':
            return 'удалил(а) счет';

        //рассылки
        case 'user.mailer.create':
            return 'создал(а) рассылку';
        case 'user.mailer.update':
            return 'обновил(а) рассылку';
        case 'user.mailer.delete':
            return 'удалил(а) рассылку';

        //сотрудники
        case 'user.user.invite':
            return 'пригласил(а) сотрудника';
        case 'user.user.fire':
            return 'уволил(а) сотрудника';
        case 'user.user.restore':
            return 'восстановил(а) сотрудника';
        case 'user.user.update':
            return 'обновил(а) карточку сотрудника';
        case 'user.user.update.subdivision':
            return 'обновил(а) карточку сотрудника';
        case 'user.user.update.role':
            return 'обновил(а) карточку сотрудника';

        //подразделения
        case 'user.subdivision.create':
            return 'создал(а) подразделение';
        case 'user.subdivision.update':
            return 'обновил(а) подразделение';
        case 'user.subdivision.update.head':
            return 'обновил(а) подразделение';
        case 'user.subdivision.delete':
            return 'удалил(а) подразделение';

        //интеграции
        case 'user.integration.create':
            return 'создал(а) интеграцию';
        case 'user.integration.responsible.add':
            return 'обновил(а) интеграцию';
        case 'user.integration.responsible.remove':
            return 'обновил(а) интеграцию';
        case 'user.integration.delete':
            return 'удалил(а) интеграцию';

        //быстрые фразы
        case 'user.script_group.create':
            return 'создал(а) группу фраз';
        case 'user.script_group.update.name':
            return 'обновил(а) группу фраз';
        case 'user.script_group.delete':
            return 'удалил(а) группу фраз';
        case 'user.script_group.script.add':
            return 'создал(а) быструю фразу';
        case 'user.script_group.script.move':
            return 'перенес(ла) быструю фразу';
        case 'user.script.update':
            return 'обновил(а) быструю фразу';

        //Автоматизация
        case 'user.automation.create':
            return 'создал(а) автоматизацию';
        case 'user.automation.update':
            return 'обновил(а) автоматизацию';
        case 'user.automation.delete':
            return 'удалил(а) автоматизацию';
        case 'user.automation.script.add':
            return 'обновил(а) автоматизацию';
        case 'user.automation.script.delete':
            return 'обновил(а) автоматизацию';
        case 'user.automation.action.add':
            return 'обновил(а) автоматизацию';
        case 'user.automation.action.delete':
            return 'обновил(а) автоматизацию';

        //роли
        case 'user.role.create':
            return 'создал(а) роль';
        case 'user.role.update.name':
            return 'обновил(а) роль';
        case 'user.role.permissions.add':
            return 'обновил(а) роль';
        case 'user.role.permissions.remove':
            return 'обновил(а) роль';
        case 'user.role.delete':
            return 'удалил(а) роль';

        //статусы сделок
        case 'user.deal_status.create':
            return 'создал(а) статус сделки';
        case 'user.deal_status.update':
            return 'обновил(а) статус сделки';
        case 'user.deal_status.delete':
            return 'удалил(а) статус сделки';

        //статусы клиентов
        case 'user.client_status.create':
            return 'создал(а) статус клиента';
        case 'user.client_status.update':
            return 'обновил(а) статус клиента';
        case 'user.client_status.delete':
            return 'удалил(а) статус клиента';

        //теги
        case 'user.tag.create':
            return 'создал(а) тег';
        case 'user.tag.update':
            return 'обновил(а) тег';
        case 'user.tag.delete':
            return 'удалил(а) тег';

        //дефолт
        default:
            return 'обновил(а)';
    }
};

export const eventField = (item) => {
    const type = item.subject_type.toLowerCase();
    const changes = item.changes;

    if (!changes.attributes || !changes.old) return;

    if (changes.attributes.curator) {
        changes.field_name = 'Куратор';
        changes.field_value = 'curator';

        changes.new_value = changes.attributes.curator.values.name;
        changes.old_value = changes.old.curator.values.name;

        return changes;
    } else if (changes.attributes.executor) {
        changes.field_name = 'Исполнитель';
        changes.field_value = 'executor';

        changes.new_value = changes.attributes.executor.values.name;
        changes.old_value = changes.old.executor.values.name;

        return changes;
    } else if (changes.attributes.end_date_from) {
        changes.field_name = 'От';
        changes.field_value = 'end_date_from';

        changes.new_value = changes.attributes.end_date_from;
        changes.old_value = changes.old.end_date_from;

        return changes;
    } else if (changes.attributes.end_date_to) {
        changes.field_name = 'До';
        changes.field_value = 'end_date_to';

        changes.new_value = changes.attributes.end_date_to;
        changes.old_value = changes.old.end_date_to;

        return changes;
    } else if (changes.attributes.text_color) {
        changes.field_name = 'Цвет текста';
        changes.field_value = 'text_color';

        changes.new_value = changes.attributes.text_color;
        changes.old_value = changes.old.text_color;

        return changes;
    } else if (changes.attributes.color) {
        if (type === 'tag') {
            changes.field_name = 'Цвет фона';
        } else if (type === 'clientstatus' || type === 'dealstatus') {
            changes.field_name = 'Цвет статуса';
        } else if (type === 'role') {
            changes.field_name = 'Цвет роли';
        }
        changes.field_value = 'color';

        changes.new_value = changes.attributes.color;
        changes.old_value = changes.old.color;

        return changes;
    } else if (changes.attributes.name) {
        if (type === 'client') {
            changes.field_name = 'Имя';
        } else {
            changes.field_name = 'Название';
        }
        changes.field_value = 'name';

        changes.new_value = changes.attributes.name;
        changes.old_value = changes.old.name;

        return changes;
    } else if (changes.attributes.stage) {
        changes.field_name = 'Стадия сделки';
        changes.field_value = 'stage';

        const stages = [
            {
                type: 'active',
                name: 'Активна',
            },
            {
                type: 'paid',
                name: 'Оплачена',
            },
            {
                type: 'canceled',
                name: 'Отменена',
            },
        ];

        changes.new_value = stages.find(i => i.type === changes.attributes.stage).name;
        changes.old_value = stages.find(i => i.type === changes.old.stage).name;

        return changes;
    } else if (changes.attributes.permission) {
        changes.field_name = 'Права';
        changes.field_value = 'name';

        changes.new_value = changes.attributes.name;
        changes.old_value = changes.old.name;

        return changes;
    } else if (changes.attributes.description) {
        changes.field_name = 'Описание';
        changes.field_value = 'description';

        changes.new_value = changes.attributes.description;
        changes.old_value = changes.old.description;

        return changes;
    } else if (changes.attributes.text) {
        changes.field_name = 'Текст';
        changes.field_value = 'text';

        changes.new_value = changes.attributes.text;
        changes.old_value = changes.old.text;

        return changes;
    } else if (changes.attributes.monthly_plan) {
        changes.field_name = 'План на месяц';
        changes.field_value = 'monthly_plan';

        changes.new_value = changes.attributes.monthly_plan;
        changes.old_value = changes.old.monthly_plan;

        return changes;
    } else if (changes.attributes.position) {
        changes.field_name = 'Должность';
        changes.field_value = 'position';

        changes.new_value = changes.attributes.position;
        changes.old_value = changes.old.position;

        return changes;
    } else if (changes.attributes.tax) {
        changes.field_name = 'НДС';
        changes.field_value = 'tax';

        changes.new_value = changes.attributes.tax;
        changes.old_value = changes.old.tax;

        return changes;
    } else if (changes.attributes.number) {
        changes.field_name = 'Номер';
        changes.field_value = 'number';

        changes.new_value = changes.attributes.number;
        changes.old_value = changes.old.number;

        return changes;
    } else if (changes.attributes.bill_date) {
        changes.field_name = 'Дата выставления счета';
        changes.field_value = 'bill_date';

        changes.new_value = `${moment(changes.attributes.bill_date).format('DD.MM.YYYY HH:mm')}`;
        changes.old_value = `${moment(changes.old.bill_date).format('DD.MM.YYYY HH:mm')}`;

        return changes;
    } else if (changes.attributes.reason) {
        changes.field_name = 'Основание для счета (договор)';
        changes.field_value = 'reason';

        changes.new_value = changes.attributes.reason;
        changes.old_value = changes.old.reason;

        return changes;
    } else if (changes.attributes.subdivision_id) {
        changes.field_name = 'Ответственное подразделение';
        changes.field_value = 'subdivision_id';

        changes.new_value = changes.attributes.subdivision_id;
        changes.old_value = changes.old.subdivision_id || 'нет';

        return changes;
    } else if (changes.attributes.price) {
        changes.field_name = 'Стоимость';
        changes.field_value = 'price';

        changes.new_value = changes.attributes.price;
        changes.old_value = changes.old.price || '0';

        return changes;
    } else if (changes.attributes.telegram_id) {
        changes.field_name = 'Telegram ID';
        changes.field_value = 'telegram_id';

        changes.new_value = changes.attributes.telegram_id;
        changes.old_value = changes.old.telegram_id;

        return changes;
    } else if (changes.attributes.telegram_url) {
        changes.field_name = 'Ссылка на чат Telegram';
        changes.field_value = 'telegram_url';

        changes.new_value = changes.attributes.telegram_url;
        changes.old_value = changes.old.telegram_url;

        return changes;
    } else if (changes.attributes.telegram_login) {
        changes.field_name = 'Логин Telegram';
        changes.field_value = 'telegram_login';

        changes.new_value = changes.attributes.telegram_login;
        changes.old_value = changes.old.telegram_login;

        return changes;
    } else if (changes.attributes.instagram_login) {
        changes.field_name = 'Логин Instagram';
        changes.field_value = 'instagram_login';

        changes.new_value = changes.attributes.instagram_login;
        changes.old_value = changes.old.instagram_login;

        return changes;
    } else if (changes.attributes.instagram_url) {
        changes.field_name = 'Ссылка на страницу Instagram';
        changes.field_value = 'instagram_url';

        changes.new_value = changes.attributes.instagram_url;
        changes.old_value = changes.old.instagram_url;

        return changes;
    } else if (changes.attributes.instagram_id) {
        changes.field_name = 'Instagram ID';
        changes.field_value = 'instagram_id';

        changes.new_value = changes.attributes.instagram_id;
        changes.old_value = changes.old.instagram_id;

        return changes;
    } else if (changes.attributes.vk_id) {
        changes.field_name = 'VK ID';
        changes.field_value = 'vk_id';

        changes.new_value = changes.attributes.vk_id;
        changes.old_value = changes.old.vk_id;

        return changes;
    } else if (changes.attributes.vk_url) {
        changes.field_name = 'Ссылка на страницу VK';
        changes.field_value = 'vk_id';

        changes.new_value = changes.attributes.vk_url;
        changes.old_value = changes.old.vk_url;

        return changes;
    } else if (changes.attributes.vk_login) {
        changes.field_name = 'Имя VK';
        changes.field_value = 'vk_login';

        changes.new_value = changes.attributes.vk_login;
        changes.old_value = changes.old.vk_login;

        return changes;
    } else if (changes.attributes.inn) {
        changes.field_name = 'ИНН';
        changes.field_value = 'inn';

        changes.new_value = changes.attributes.inn;
        changes.old_value = changes.old.inn;

        return changes;
    } else if (changes.attributes.kpp) {
        changes.field_name = 'КПП';
        changes.field_value = 'kpp';

        changes.new_value = changes.attributes.kpp;
        changes.old_value = changes.old.kpp;

        return changes;
    } else if (changes.attributes.gender) {
        changes.field_name = 'Пол';
        changes.field_value = 'gender';

        if (changes.attributes.gender === 'm') {
            changes.new_value = 'Мужской';
        } else {
            changes.new_value = 'Женский';
        }

        if (changes.old.gender === 'm') {
            changes.old_value = 'Мужской';
        } else if (changes.old.gender === 'f') {
            changes.old_value = 'Женский';
        }

        return changes;
    } else if (changes.attributes.country) {
        changes.field_name = 'Страна';
        changes.field_value = 'country';

        changes.new_value = changes.attributes.country;
        changes.old_value = changes.old.country;

        return changes;
    } else if (changes.attributes.address) {
        changes.field_name = 'Адрес';
        changes.field_value = 'address';

        changes.new_value = changes.attributes.address;
        changes.old_value = changes.old.address;

        return changes;
    } else if (changes.attributes.city) {
        changes.field_name = 'Город';
        changes.field_value = 'city';

        changes.new_value = changes.attributes.city;
        changes.old_value = changes.old.city;

        return changes;
    } else if (changes.attributes.phone) {
        changes.field_name = 'Телефон';
        changes.field_value = 'phone';

        changes.new_value = changes.attributes.phone;
        changes.old_value = changes.old.phone;

        return changes;
    } else if (changes.attributes.email) {
        changes.field_name = 'Email';
        changes.field_value = 'email';

        changes.new_value = changes.attributes.email;
        changes.old_value = changes.old.email;

        return changes;
    } else if (changes.attributes.email) {
        changes.field_name = 'Email';
        changes.field_value = 'email';

        changes.new_value = changes.attributes.email;
        changes.old_value = changes.old.email;

        return changes;
    } else if (changes.attributes.birth) {
        changes.field_name = 'Дата рождения';
        changes.field_value = 'birth';

        changes.new_value = changes.attributes.birth;
        changes.old_value = changes.old.birth;

        return changes;
    } else if (changes.attributes.integration_id) {
        changes.field_name = 'Интеграция';
        changes.field_value = 'integration_id';

        changes.new_value = changes.attributes.integration_id;
        changes.old_value = changes.old.integration_id;

        return changes;
    } else if (changes.attributes.send_date) {
        changes.field_name = 'Дата';
        changes.field_value = 'send_date';

        changes.new_value = changes.attributes.send_date;
        changes.old_value = changes.old.send_date;

        return changes;
    } else if (changes.attributes.send_time) {
        changes.field_name = 'Время';
        changes.field_value = 'send_time';

        changes.new_value = changes.attributes.send_time;
        changes.old_value = changes.old.send_time;

        return changes;
    } else if (changes.attributes.next_contact_date) {
        changes.field_name = 'Дата следующего контакта';
        changes.field_value = 'next_contact_date';

        changes.new_value = moment(changes.attributes.next_contact_date).format('DD.MM.YYYY');
        changes.old_value = moment(changes.old.next_contact_date).format('DD.MM.YYYY');

        return changes;
    } else if (changes.attributes.message) {
        changes.field_name = 'Сообщение';
        changes.field_value = 'message';

        changes.new_value = changes.attributes.message;
        changes.old_value = changes.old.message;

        return changes;
    } else if (changes.attributes.notify === false || changes.attributes.notify === true) {
        changes.field_name = 'Уведомление о выполении';
        changes.field_value = 'notify';

        changes.new_value = changes.attributes.notify ? 'Включено' : 'Выключено';
        changes.old_value = changes.old.notify ? 'Включено' : 'Выключено';

        return changes;
    } else if (changes.attributes.delivery_cost) {
        changes.field_name = 'Стоимость доставки';
        changes.field_value = 'delivery_cost';

        changes.new_value = changes.attributes.delivery_cost;
        changes.old_value = changes.old.delivery_cost;

        return changes;
    } else if (changes.attributes.discount) {
        changes.field_name = 'Скидках';
        changes.field_value = 'discount';

        changes.new_value = changes.attributes.discount + '%';
        changes.old_value = changes.old.discount + '%';

        return changes;
    } else if (changes.attributes.prepayment) {
        changes.field_name = 'Предоплата';
        changes.field_value = 'prepayment';

        changes.new_value = changes.attributes.prepayment;
        changes.old_value = changes.old.prepayment;

        return changes;
    } else if (changes.attributes.client_pay_delivery) {
        changes.field_name = 'Клиент оплачивает доставку';
        changes.field_value = 'client_pay_delivery';

        changes.new_value = changes.attributes.client_pay_delivery ? 'Да' : 'Нет';
        changes.old_value = changes.old.client_pay_delivery ? 'Да' : 'Нет';

        return changes;
    } else if (changes.attributes.total_sum) {
        changes.field_name = 'Итоговая сумма';
        changes.field_value = 'total_sum';

        changes.new_value = changes.attributes.total_sum;
        changes.old_value = changes.old.total_sum;

        return changes;
    } else if (changes.attributes.sum) {
        changes.field_name = 'Сумма корзины';
        changes.field_value = 'sum';

        changes.new_value = changes.attributes.sum;
        changes.old_value = changes.old.sum;

        return changes;
    } else if (changes.attributes.payment_upon_receipt) {
        changes.field_name = 'При получении';
        changes.field_value = 'payment_upon_receipt';

        changes.new_value = changes.attributes.payment_upon_receipt;
        changes.old_value = changes.old.payment_upon_receipt;

        return changes;
    } else if (changes.attributes['delivery.postal_status']) {
        changes.field_name = 'Статус доставки';
        changes.field_value = 'delivery.postal_status';

        changes.new_value = changes.attributes['delivery.postal_status'];
        changes.old_value = changes.old['delivery.postal_status'];

        return changes;
    } else if (changes.attributes['delivery.client_comment']) {
        changes.field_name = 'Примечание клиента';
        changes.field_value = 'delivery.client_comment';

        changes.new_value = changes.attributes['delivery.client_comment'];
        changes.old_value = changes.old['delivery.client_comment'];

        return changes;
    } else if (changes.attributes['delivery.internal_comment']) {
        changes.field_name = 'Примечание к доставке';
        changes.field_value = 'delivery.internal_comment';

        changes.new_value = changes.attributes['delivery.internal_comment'];
        changes.old_value = changes.old['delivery.internal_comment'];

        return changes;
    } else if (changes.attributes['delivery.country']) {
        changes.field_name = 'Страна';
        changes.field_value = 'delivery.country';

        changes.new_value = changes.attributes['delivery.country'];
        changes.old_value = changes.old['delivery.country'];

        return changes;
    } else if (changes.attributes['delivery.type']) {
        changes.field_name = 'Служба доставки';
        changes.field_value = 'delivery.type';

        changes.new_value = changes.attributes['delivery.type'];
        changes.old_value = changes.old['delivery.type'];

        return changes;
    } else if (changes.attributes['delivery.method']) {
        changes.field_name = 'Способ доставки';
        changes.field_value = 'delivery.method';

        changes.new_value = changes.attributes['delivery.method'];
        changes.old_value = changes.old['delivery.method'];

        return changes;
    } else if (changes.attributes['delivery.full_name']) {
        changes.field_name = 'ФИО';
        changes.field_value = 'delivery.full_name';

        changes.new_value = changes.attributes['delivery.full_name'];
        changes.old_value = changes.old['delivery.full_name'];

        return changes;
    } else if (changes.attributes['delivery.mobile_phone']) {
        changes.field_name = 'Мобильный телефон';
        changes.field_value = 'delivery.mobile_phone';

        changes.new_value = changes.attributes['delivery.mobile_phone'];
        changes.old_value = changes.old['delivery.mobile_phone'];

        return changes;
    } else if (changes.attributes['delivery.postal_code']) {
        changes.field_name = 'Индекс';
        changes.field_value = 'delivery.postal_code';

        changes.new_value = changes.attributes['delivery.postal_code'];
        changes.old_value = changes.old['delivery.postal_code'];

        return changes;
    } else if (changes.attributes['delivery.city']) {
        changes.field_name = 'Город';
        changes.field_value = 'delivery.city';

        changes.new_value = changes.attributes['delivery.city'];
        changes.old_value = changes.old['delivery.city'];

        return changes;
    } else if (changes.attributes['delivery.address']) {
        changes.field_name = 'Адрес';
        changes.field_value = 'delivery.address';

        changes.new_value = changes.attributes['delivery.address'];
        changes.old_value = changes.old['delivery.address'];

        return changes;
    } else if (changes.attributes['delivery.send_date']) {
        changes.field_name = 'Дата отправки';
        changes.field_value = 'delivery.send_date';

        changes.new_value = changes.attributes['delivery.send_date'];
        changes.old_value = changes.old['delivery.send_date'];

        return changes;
    } else if (changes.attributes['delivery.pickup_date']) {
        changes.field_name = 'Дата доставки';
        changes.field_value = 'delivery.pickup_date';

        changes.new_value = changes.attributes['delivery.pickup_date'];
        changes.old_value = changes.old['delivery.pickup_date'];

        return changes;
    } else if (changes.attributes['delivery.tracking_number']) {
        changes.field_name = 'Трек номер';
        changes.field_value = 'delivery.tracking_number';

        changes.new_value = changes.attributes['delivery.tracking_number'];
        changes.old_value = changes.old['delivery.tracking_number'];

        return changes;
    } else if (changes.attributes['delivery.pickup_time_from']) {
        changes.field_name = 'Время доставки с';
        changes.field_value = 'delivery.pickup_time_from';

        changes.new_value = changes.attributes['delivery.pickup_time_from'];
        changes.old_value = changes.old['delivery.pickup_time_from'];

        return changes;
    } else if (changes.attributes['delivery.pickup_time_to']) {
        changes.field_name = 'Время доставки по';
        changes.field_value = 'delivery.pickup_time_to';

        changes.new_value = changes.attributes['delivery.pickup_time_to'];
        changes.old_value = changes.old['delivery.pickup_time_to'];

        return changes;
    } else if (changes.attributes['delivery.cdek.cdek_recipient_phone']) {
        changes.field_name = 'Номер телефона получателя';
        changes.field_value = 'delivery.cdek.cdek_recipient_phone';

        changes.new_value = changes.attributes['delivery.cdek.cdek_recipient_phone'];
        changes.old_value = changes.old['delivery.cdek.cdek_recipient_phone'];

        return changes;
    } else if (changes.attributes['delivery.cdek.cdek_recipient_name']) {
        changes.field_name = 'ФИО получателя';
        changes.field_value = 'delivery.cdek.cdek_recipient_name';

        changes.new_value = changes.attributes['delivery.cdek.cdek_recipient_name'];
        changes.old_value = changes.old['delivery.cdek.cdek_recipient_name'];

        return changes;
    } else if (changes.attributes['delivery.cdek.cdek_recipient_company']) {
        changes.field_name = 'Название компании';
        changes.field_value = 'delivery.cdek.cdek_recipient_company';

        changes.new_value = changes.attributes['delivery.cdek.cdek_recipient_company'];
        changes.old_value = changes.old['delivery.cdek.cdek_recipient_company'];

        return changes;
    } else if (changes.attributes['delivery.cdek.cdek_package_weight']) {
        changes.field_name = 'Вес посылки (граммы)';
        changes.field_value = 'delivery.cdek.cdek_package_weight';

        changes.new_value = changes.attributes['delivery.cdek.cdek_package_weight'];
        changes.old_value = changes.old['delivery.cdek.cdek_package_weight'];

        return changes;
    } else if (changes.attributes['delivery.cdek.cdek_package_length']) {
        changes.field_name = 'Длина посылки (см)';
        changes.field_value = 'delivery.cdek.cdek_package_length';

        changes.new_value = changes.attributes['delivery.cdek.cdek_package_length'];
        changes.old_value = changes.old['delivery.cdek.cdek_package_length'];

        return changes;
    } else if (changes.attributes['delivery.cdek.cdek_package_width']) {
        changes.field_name = 'Ширина посылки (см)';
        changes.field_value = 'delivery.cdek.cdek_package_width';

        changes.new_value = changes.attributes['delivery.cdek.cdek_package_width'];
        changes.old_value = changes.old['delivery.cdek.cdek_package_width'];

        return changes;
    } else if (changes.attributes['delivery.cdek.cdek_package_height']) {
        changes.field_name = 'Высота посылки (см)';
        changes.field_value = 'delivery.cdek.cdek_package_height';

        changes.new_value = changes.attributes['delivery.cdek.cdek_package_height'];
        changes.old_value = changes.old['delivery.cdek.cdek_package_height'];

        return changes;
    } else if (changes.attributes['delivery.cdek.cdek_from_address']) {
        changes.field_name = 'С адреса';
        changes.field_value = 'delivery.cdek.cdek_from_address';

        changes.new_value = changes.attributes['delivery.cdek.cdek_from_address'];
        changes.old_value = changes.old['delivery.cdek.cdek_from_address'];

        return changes;
    } else if (changes.attributes['delivery.cdek.cdek_to_address']) {
        changes.field_name = 'На адрес';
        changes.field_value = 'delivery.cdek.cdek_to_address';

        changes.new_value = changes.attributes[changes.field_value];
        changes.old_value = changes.old[changes.field_value];

        return changes;
    } else if (changes.attributes['delivery.cdek.cdek_from_city_id']) {
        changes.field_name = 'Откуда (населенный пункт)';
        changes.field_value = 'delivery.cdek.cdek_from_city_id';

        changes.new_value = changes.attributes[changes.field_value];
        changes.old_value = changes.old[changes.field_value];

        return changes;
    } else if (changes.attributes['delivery.cdek.cdek_to_city_id']) {
        changes.field_name = 'Куда (населенный пункт)';
        changes.field_value = 'delivery.cdek.cdek_to_city_id';

        changes.new_value = changes.attributes[changes.field_value];
        changes.old_value = changes.old[changes.field_value];

        return changes;
    } else if (changes.attributes['delivery.cdek.cdek_tariff_id']) {
        changes.field_name = 'СДЭК Тариф';
        changes.field_value = 'delivery.cdek.cdek_tariff_id';

        changes.new_value = changes.attributes[changes.field_value];
        changes.old_value = changes.old[changes.field_value];

        return changes;
    } else if (changes.attributes['delivery.cdek.cdek_from_office_id']) {
        changes.field_name = 'Откуда (ПВЗ)';
        changes.field_value = 'delivery.cdek.cdek_from_office_id';

        changes.new_value = changes.attributes[changes.field_value];
        changes.old_value = changes.old[changes.field_value];

        return changes;
    } else if (changes.attributes['delivery.cdek.cdek_to_office_id']) {
        changes.field_name = 'Куда (ПВЗ)';
        changes.field_value = 'delivery.cdek.cdek_to_office_id';

        changes.new_value = changes.attributes[changes.field_value];
        changes.old_value = changes.old[changes.field_value];

        return changes;
    } else if (!changes.new_value && !changes.old_value) {
        changes.field_name = Object.keys(changes.attributes)[0];
        changes.field_value = Object.keys(changes.attributes)[0];
        changes.new_value = changes.attributes[Object.keys(changes.attributes)[0]];
        changes.old_value = changes.attributes[Object.keys(changes.attributes)[0]];

        return changes;
    } else {
        return null;
    }
};

export const eventTypeLink = (type) => {

    switch (type) {
        //задачи
        case 'task':
            return 'tasks';
        case 'client':
            return 'clients';
        case 'product':
            return 'products';
        case 'deal':
            return 'deals';
        case 'bill':
            return 'bills';
        case 'user':
            return 'users';
        case 'subdivision':
            return 'subdivisions';
        case 'integration':
            return 'integrations';
        case 'script':
            return 'scripts';
        case 'automation':
            return 'automations';
        case 'permission':
            return 'permissions';

        //дефолт
        default:
            return 'users';
    }
}
