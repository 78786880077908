<template>
  <v-row class="event-log">
    <v-col cols="3" class="mr-6 event-log__navigation">
      <h3 class="event-log__navigation-headline">Разделы</h3>
      <div class="event-log__navigation-item d-flex" :class="{'font-weight-bold' : isNavigationActive(item.value)}"
           v-for="(item, index) in navigation" :key="index" @click="selectSection(item.value)">
        <img :src="require(`@/assets/img/event-log/${item.value}.svg`)" class="mr-3" alt="icon"/>
        {{ item.title }}
        <div class="event-log__navigation-item--active" v-if="isNavigationActive(item.value)"></div>
      </div>
    </v-col>
    <v-col class="event-log__wrapper">
      <h3 class="event-log__wrapper-headline">Лента событий — <span>{{ eventHeadline }}</span></h3>
      <EsFiltration :showByOptions="showByOptions" :itemsLength="tableMeta.total" :showBy="15" @updatePages="getItems"
                    noOptions :filter="filters" sortable>

        <template slot="sort-menu">
          <v-list dense class="settings-menu">
            <v-list-item-group>
              <v-list-item>
                <v-list-item-title>
                  <v-autocomplete
                      class="evi-autocomplete"
                      color="#44D370"
                      :items="users"
                      v-model="filters['filter[causer_id]']"
                      item-text="name"
                      item-value="id"
                      hide-details
                      :loading="userLoading"
                      @focus="loadUsers"
                      :search-input.sync="userSearch"
                      @input.native="searchUser(userSearch)"
                      clearable
                      item-color="green"
                  >
                    <template v-slot:label>
                      Фильтровать по сотрудникам
                    </template>
                    <template v-slot:append-item>
                      <div v-intersect="userIntersect"/>
                    </template>
                    <template v-slot:item="{item}">
                      <v-avatar
                          color="#FFFFFF"
                          size="30"
                          class="user-page__avatar mr-3"
                      >
                        <img
                            :src="item.avatar_url ||require('@/assets/img/users/profile-avatar.png')"
                            alt=""
                        >
                      </v-avatar>
                      <div class="v-list-item__title">{{ item.name }}</div>
                    </template>
                    <template v-slot:append>
                                    <span class="evi-autocomplete__arrow">
                                        <v-icon>mdi-chevron-down</v-icon>
                                    </span>
                    </template>
                  </v-autocomplete>
                </v-list-item-title>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </template>
      </EsFiltration>
      <div
          v-if="loading" class="event-log__items w-100">
        <v-skeleton-loader
            type="card-heading"
            v-for="i in 15"
            :key="i"
            class="w-100 item-event-loading"
        />
      </div>
      <div class="event-log__items" v-if="events && events.length && !loading">
        <div class="event-log__item" v-for="(item) in events" :key="item.id">
          <span class="event-log__item-time text-no-wrap mr-6">{{
              item.created_at | moment('D MMM YYYY, HH:mm')
            }}</span>
          <img :src="require(`@/assets/img/event-log/${item.subject_type.toLowerCase()}.svg`)" class="mr-6" alt="icon"/>
          <div v-if="item.causer && item.subject">
            <router-link class="event-log__item-link" :to="`/users/${item.causer.id}`">
              {{ item.causer.name }}
            </router-link>
            {{ eventText(item.event) }}
            <div v-if="item.changes && item.changes.attributes && item.event">
              <router-link v-if="item.subject_type.toLowerCase() === 'tag'" class="event-log__item-tag"
                           :to="`/tags`"
                           :style="{backgroundColor: `${item.changes.attributes.color}`, color: `${item.changes.attributes.text_color}` }">
                {{ item.subject.name || item.changes.attributes.name }}
              </router-link>
              <router-link v-else-if="item.subject_type.toLowerCase() === 'clientstatus'" class="event-log__item-status"
                           :to="`/client-statuses`"
                           :style="{color: `${item.changes.attributes.color}` }">
                {{ item.subject.name || item.changes.attributes.name }}
              </router-link>
              <router-link v-else-if="item.subject_type.toLowerCase() === 'dealstatus'" class="event-log__item-status"
                           :to="`/deal-statuses`"
                           :style="{color: `${item.changes.attributes.color}` }">
                {{ item.subject.name || item.changes.attributes.name }}
              </router-link>
              <router-link v-else-if="item.subject_type.toLowerCase() === 'role'" class="event-log__item-status"
                           :to="`/permissions`"
                           :style="{color: `${item.changes.attributes.color}` }">
                {{ item.subject.name || item.changes.attributes.name }}
              </router-link>
              <router-link v-else-if="item.subject_type.toLowerCase() === 'automation'" class="event-log__item-link"
                           :to="`/automations?filter[name]=${item.subject.name}`">
                {{ item.subject.name || item.changes.attributes.name }}
              </router-link>
              <router-link v-else-if="item.subject_type.toLowerCase() === 'scriptgroup'" class="event-log__item-link"
                           :to="`/scripts`">
                {{ item.subject.name || item.changes.attributes.name }}
              </router-link>
              <router-link v-else-if="item.subject_type.toLowerCase() === 'script'" class="event-log__item-link"
                           :to="`/scripts`">
                {{ item.subject.name || item.changes.attributes.name }}
              </router-link>
              <router-link v-else-if="item.subject_type.toLowerCase() === 'mailer'" class="event-log__item-link"
                           :to="`/mailers/${item.subject.id}`">
                {{ item.subject.name || item.changes.attributes.name }}
              </router-link>
              <router-link v-else class="event-log__item-link"
                           :to="`/${eventTypeLink(item.subject_type.toLowerCase())}/${item.subject.id}`">
                {{ item.subject.name || item.changes.attributes.name }}
              </router-link>
              .
              <div class="d-inline-block" v-if="eventField(item) && !item.event.includes('role.permissions')">
                Значение поля "{{ eventField(item).field_name }}"
                было изменено с "{{ eventField(item).old_value }}" на "{{ eventField(item).new_value }}".
              </div>
              <div class="d-inline-block" v-if="item.event.includes('role.permissions.add')">
                Добавлено право "{{ item.changes.attributes.permission.name }}".
              </div>
              <div class="d-inline-block" v-else-if="item.event.includes('role.permissions.remove')">
                Удалено право "{{ item.changes.attributes.permission.name }}".
              </div>
              <div class="d-inline-block" v-else-if="item.event.includes('comment.create')">
                Был добавлен комментарий.
              </div>
              <div class="d-inline-block" v-else-if="item.event.includes('integration.responsible.add')">
                Был добавлен ответственный
                <router-link class="event-log__item-link" v-if="item.changes.attributes.user.values"
                             :to="`/users/${item.changes.attributes.user.values.id}`">
                  {{ item.changes.attributes.user.values.name }}
                </router-link>
                .
              </div>
              <div class="d-inline-block" v-else-if="item.event.includes('integration.responsible.remove')">
                Был удален ответственный
                <router-link class="event-log__item-link" v-if="item.changes.attributes.user.values"
                             :to="`/users/${item.changes.attributes.user.values.id}`">
                  {{ item.changes.attributes.user.values.name }}
                </router-link>
                .
              </div>
              <div class="d-inline-block" v-else-if="item.event.includes('update.responsible_user')">
                Смена ответственного сотрудника с
                <router-link class="event-log__item-link" v-if="item.changes.attributes.responsibleUser.values"
                             :to="`/users/${item.changes.attributes.responsibleUser.values.id}`">
                  {{ item.changes.attributes.responsibleUser.values.name }}
                </router-link>
                на
                <router-link class="event-log__item-link" v-if="item.changes.old.responsibleUser.values"
                             :to="`/users/${item.changes.old.responsibleUser.values.id}`">
                  {{ item.changes.old.responsibleUser.values.name }}
                </router-link>
                .
              </div>
              <div class="d-inline-block" v-else-if="item.event.includes('user.update.subdivision')">
                Перевод в подразделение
                <router-link class="event-log__item-link" v-if="item.changes.attributes.subdivision.values"
                             :to="`/subdivisions/${item.changes.attributes.subdivision.values.id}`">
                  {{ item.changes.attributes.subdivision.values.name }}
                </router-link>
                из подразделения
                <router-link class="event-log__item-link" v-if="item.changes.old.subdivision.values"
                             :to="`/subdivisions/${item.changes.old.subdivision.values.id}`">
                  {{ item.changes.old.subdivision.values.name }}
                </router-link>
                .
              </div>
              <div class="d-inline-block" v-else-if="item.event.includes('client.update.status')">
                Смена статуса
                <div class="text d-inline font-weight-bold"
                     v-if="item.changes && item.changes.old && item.changes.old.clientStatus && item.changes.old.clientStatus.values"
                     :style="{color: `${item.changes.old.clientStatus.values.color}`}">
                  {{ item.changes.old.clientStatus.values.name }}
                </div>
                на
                <div class="text d-inline font-weight-bold"
                     v-if="item.changes && item.changes.attributes && item.changes.attributes.clientStatus && item.changes.attributes.clientStatus.values"
                     :style="{color: `${item.changes.attributes.clientStatus.values.color}`}">
                  {{ item.changes.attributes.clientStatus.values.name }}
                </div>
                .
              </div>
              <div class="d-inline-block" v-else-if="item.event.includes('deal.update.status')">
                Смена статуса
                <div class="text d-inline font-weight-bold"
                     v-if="item.changes && item.changes.old && item.changes.old.dealStatus && item.changes.old.dealStatus.values"
                     :style="{color: `${item.changes.old.dealStatus.values.color}`}">
                  {{ item.changes.old.dealStatus.values.name }}
                </div>
                на
                <div class="text d-inline font-weight-bold"
                     v-if="item.changes && item.changes.attributes && item.changes.attributes.dealStatus && item.changes.attributes.dealStatus.values"
                     :style="{color: `${item.changes.attributes.dealStatus.values.color}`}">
                  {{ item.changes.attributes.dealStatus.values.name }}
                </div>
                .
              </div>
              <div class="d-inline-block" v-else-if="item.event.includes('deal.product.add')">
                Был добавлен продукт
                <router-link class="event-log__item-link" v-if="item.changes.attributes.product.values"
                             :to="`/products/${item.changes.attributes.product.values.id}`">
                  {{ item.changes.attributes.product.values.name }}
                </router-link>
                .
              </div>
              <div class="d-inline-block" v-else-if="item.event.includes('deal.product.delete')">
                Был удален продукт
                <router-link class="event-log__item-link" v-if="item.changes.attributes.product.values"
                             :to="`/products/${item.changes.attributes.product.values.id}`">
                  {{ item.changes.attributes.product.values.name }}
                </router-link>
                .
              </div>
              <div class="d-inline-block" v-else-if="item.event.includes('product.size.create')">
                Был добавлен размер продукта
                <router-link class="event-log__item-link" v-if="item.subject.id"
                             :to="`/products/${item.subject.id}`">
                  {{ item.changes.attributes.size.values.value }}
                </router-link>
                .
              </div>
              <div class="d-inline-block" v-else-if="item.event.includes('product.size.update.price')">
                Была изменена цена размера продукта
                c
                "{{ item.changes.old.size.values.price }}" на "{{ item.changes.attributes.size.values.price }}".
              </div>
              <div class="d-inline-block" v-else-if="item.event.includes('product.size.update.value')">
                Было изменено название размера продукта
                c
                "{{ item.changes.old.size.values.value }}" на "{{ item.changes.attributes.size.values.value }}".
              </div>
            </div>
            <router-link v-else class="event-log__item-link"
                         :to="`/${eventTypeLink(item.subject_type.toLowerCase())}/${item.subject.id}`">
              {{ item.subject.name }}
            </router-link>
            <span v-if="!item.changes.attributes">.</span>
          </div>
        </div>
      </div>
      <div class="no-events" v-else-if="!loading">
        Событий нет
      </div>
    </v-col>
  </v-row>
</template>

<script>
import EsFiltration from "@/components/сommon/EsTable/EsFiltration";
import {mapActions, mapState} from "vuex";
import {eventText, eventField, eventTypeLink} from "@/components/event-log/allEvents";
import {UserAutocompleteRequestsMixin} from "@/mixins/autocomplete/UserAutocompleteRequestsMixin";
import {RouteFilterPaginationMixin} from "../../mixins/RouteFilterPaginationMixin";

export default {
  name: 'EventLog',
  mixins: [UserAutocompleteRequestsMixin, RouteFilterPaginationMixin],
  components: {EsFiltration},
  data: () => ({
    showByOptions: [
      {
        text: '5',
        counter: 5,
      },
      {
        text: '10',
        counter: 10,
      },
      {
        text: '15',
        counter: 15,
      },
      {
        text: '30',
        counter: 30,
      },
      {
        text: '50',
        counter: 50,
      },
      {
        text: '100',
        counter: 100,
      },
    ],
    navigation: [
      {
        title: 'Все',
        value: 'all',
      },
      {
        title: 'Задачи',
        value: 'task',
      },
      {
        title: 'Клиенты',
        value: 'client',
      },
      {
        title: 'Продукты',
        value: 'product',
      },
      {
        title: 'Сделки',
        value: 'deal',
      },
      {
        title: 'Счета',
        value: 'bill',
      },
      {
        title: 'Рассылки',
        value: 'mailer',
      },
      {
        title: 'Сотрудники',
        value: 'user',
      },
      {
        title: 'Подразделения',
        value: 'subdivision',
      },
      {
        title: 'Интеграции',
        value: 'integration',
      },
      {
        title: 'Быстрые фразы',
        value: 'scriptgroup',
      },
      {
        title: 'Автоматизация',
        value: 'automation',
      },
      {
        title: 'Роли и права',
        value: 'role',
      },
      {
        title: 'Статусы сделок',
        value: 'dealstatus',
      },
      {
        title: 'Статусы клиентов',
        value: 'clientstatus',
      },
      {
        title: 'Теги',
        value: 'tag',
      },
    ],
    selectedUser: null,
    selectedSection: null,
    filters: {
      'filter[causer_id]': undefined,
      'section': undefined,
    }
  }),
  computed: {
    ...mapState("event-log", ["events",]),
    ...mapState("common", ["tableMeta", "loading"]),
    eventHeadline() {
      if (this.selectedSection) {
        return this.navigation.find(i => i.value === this.selectedSection).title;
      }
      return 'Все';
    }
  },
  methods: {
    ...mapActions({
      getItems: 'event-log/getItemsPages',
    }),
    isNavigationActive(value) {
      return value === this.filters['section'] || (value === 'all' && !this.filters['section']);
    },
    eventText(event) {
      return eventText(event);
    },
    eventField(changes) {
      if (eventField(changes)) return eventField(changes);
      return null;
    },
    eventTypeLink: eventTypeLink,
    selectSection(value) {
      if (value !== 'all') {
        this.filters['section'] = value;
      } else {
        this.filters['section'] = null;
      }
    }
  },
  mounted() {
    this.routeFiltersInit(this.filters);
  },
  watch: {
    filters: {
      handler (val) {
        this.filterWatcher(val);
      },
      deep: true,
    },
  },
}
</script>

<style lang="scss">
.event-log {
  &__wrapper {
    background: #FFFFFF;
    //box-shadow: 10px -2px 54px rgba(0, 0, 0, 0.06);
    border-radius: 10px;
    padding: 60px 25px;
    margin-bottom: 25px;

    &-headline {
      margin-left: 20px;

      span {
        font-weight: 400;
      }
    }
  }

  &__navigation {
    display: flex;
    flex-direction: column;
    max-width: 250px;

    &-headline {
      font-weight: 400;
      font-size: 20px;
      margin-bottom: 66px;
    }

    &-item {
      box-shadow: 0 20px 24px -4px rgba(34, 37, 57, 0.08), 0px 8px 8px -4px rgba(34, 37, 57, 0.03);
      border-radius: 10px;
      padding: 12px 18px;
      align-items: center;
      margin-bottom: 9px;
      font-weight: 400;
      font-size: 14px;
      color: $evi-headline-color;
      cursor: pointer;
      background: #fff;
      position: relative;

      &--active {
        display: block;
        position: absolute;
        content: '';
        border: 6px solid transparent;
        border-left: 6px solid #808386;
        border-radius: 1px;
        right: 14px;
      }
    }
  }

  .no-events {
    font-weight: 600;
    font-size: 14px;
    color: #5C5C5C;
    display: flex;
    justify-content: center;
    border-top-right-radius: 32px;
    border-bottom-right-radius: 32px;
    background: transparent;
  }

  &__items {
    padding: 0 20px 20px 20px;
  }

  &__item {
    padding: 21px 6px;
    border-bottom: 1px solid #DDDFE1;
    display: flex;
    align-items: flex-start;
    font-size: 14px;

    &-time {
      font-weight: 400;
      font-size: 14px;
      color: $evi-grey-color;
    }

    &-link {
      font-weight: 700;
      color: $evi-headline-color !important;
      text-decoration: none;
      font-size: 14px;
    }

    &-status {
      font-weight: 700;
      text-decoration: none;
    }

    &-tag {
      font-weight: 700;
      color: $evi-headline-color !important;
      text-decoration: none;
      background: #0064FA;
      border-radius: 2px;
      padding: 0 12px;
    }
  }

  .item-event-loading .v-skeleton-loader__heading {
    height: 40px;
    width: auto;
  }
}
</style>
